<template>
    <div id="page-service-characteristic" :class="{ 'on-sidebar': on_sidebar }">
        <app-loader v-if="loading" fixed-on="desktop"></app-loader>

        <div class="container">
            <div class="row" v-if="!on_sidebar">
                <div class="col-12">
                    <router-link class="btn btn-back-to" :to="service_route">Back to Service</router-link>

                    <div class="header-info">
                        <h1 class="heading"><span v-if="is_new">Adding</span> Service <span v-if="service.Name">"{{ service.Name }}"</span> Characteristic <span v-if="original.Name">"{{ original.Name }}"</span></h1>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-mob-12"
                    :class="on_sidebar ? 'col-12' : 'col-6'"
                >
                    <div class="board">
                        <h3 class="heading">Details</h3>
                        
                        <app-loader v-if="processing"></app-loader>

                        <app-error v-model="errors.save"></app-error>

                        <app-input           v-model="characteristic.Name"            label="Name"           type="text"                 :error="errors.Name"         @change="errors.Name = null"         :disabled="disabled" :maxlength="64"></app-input>
                        <app-input           v-model="characteristic.ReplacesUUID"    label="ReplacesUUID"   type="text"                 :error="errors.ReplacesUUID" @change="errors.ReplacesUUID = null" :disabled="disabled" :maxlength="36"></app-input>
                        <app-input           v-model="characteristic.DefaultValue"    label="Default Value"  type="text"                 :error="errors.DefaultValue" @change="errors.DefaultValue = null" :disabled="disabled" :maxlength="128"></app-input>
                        <app-dropdown-select v-model="characteristic.Required"        label="Required"       :options="dictonaries.bool" :error="errors.Required"     @change="errors.Required = null"     :disabled="disabled"></app-dropdown-select>
                        <app-dropdown-select v-model="characteristic.ValueType"       label="Value Type"     :options="dictonaries.type" :error="errors.ValueType"    @change="errors.ValueType = null"    :disabled="disabled"></app-dropdown-select>
                        <app-input           v-model="characteristic.ValidationRules" label="ValidationRules" type="text"                :error="errors.ValidationRules" @change="errors.ValidationRules = null" :disabled="disabled" :maxlength="36"></app-input>
                        <app-input           v-model="characteristic.Value"           label="Value"           type="text"                :error="errors.Value" @change="errors.Value = null" :disabled="disabled" :maxlength="36"></app-input>
                        <app-dropdown-select v-model="characteristic.CanAdd"          label="Allow Product to override on Add"           :options="dictonaries.bool" :error="errors.CanAdd"       @change="errors.CanAdd = null"       :disabled="disabled"></app-dropdown-select>
                        <app-dropdown-select v-model="characteristic.CanUpdate"       label="Allow Product to override on Update"        :options="dictonaries.bool" :error="errors.CanUpdate"    @change="errors.CanUpdate = null"    :disabled="disabled"></app-dropdown-select>

                        <button class="btn btn-small btn-primary" @click="save" :disabled="disabled || !is_changed" v-if="!on_sidebar">Save</button>
                    </div>

                    <button class="btn btn-full-width btn-primary" @click="save" :disabled="disabled || !is_changed" v-if="on_sidebar">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'

import appInput from '@/components/app-input'
import appDropdownSelect from '@/components/app-dropdown-select'

import ServiceCharacteristicsValidator from '@/validators/service-characteristics-validator'
import errMessage from '@/helpers/errMessage'
import formatDateNano from '@/helpers/format-date-nano'

export default {
    props: {
        uuid: { type: String, default: null },
        charUuid: { type: String, default: null },
    },

    components: {
        appLoader,
        appError,
        
        appInput,
        appDropdownSelect,
    },

    data() {
        return {
            service: {},

            characteristic: {
                Name: null,
                DefaultValue: null,
                Required: null,
                ValueType: null,
                CanAdd: false,
                CanUpdate: false,
            },
            original: {},

            dictonaries: {
                bool: [
                    { id: true,  name: 'Yes' },
                    { id: false, name: 'No'  },
                ],
                bool_with_blank: [
                    { id: '',    name: '(blank)' },
                    { id: true,  name: 'Yes'     },
                    { id: false, name: 'No'      },
                ],
                type: [
                    { id: '',       name: '(none)' },
                    { id: 'bool',   name: 'Bool'   },
                    { id: 'string', name: 'String' },
                    { id: 'number', name: 'Number' },
                    { id: 'MSISDN', name: 'MSISDN' },
                    { id: 'IMSI',   name: 'IMSI'   },
                ],
            },

            loading: false,
            processing: false,

            errors: {},

            on_sidebar: false,
        }
    },

    created() {
        this.init()
    },

    mounted() {
        this.on_sidebar = this.$parent.$el.classList.contains('app-sidebar-details')
    },

    methods: {
        init() {
            this.loadServiceCharacteristic()
        },

        loadServiceCharacteristic() {
            const loading = `${ this.prod_uuid } - ${ this.char_uuid }`

            if (this.loading != loading) {
                this.loading = loading
                this.processing = false
                this.errors = {}

                return this.$store.dispatch('api_service_catalog/getCachedServiceCatalogEntryByUUID', { UUID: this.prod_uuid }).then(service => {
                    if (this.loading == loading) {
                        this.service = service
                        
                        if (this.is_new) {
                            for (const key in this.characteristic) {
                                this.characteristic[ key ] = null
                            }
                            this.original = { ...this.characteristic }

                            this.loading = false
                        } else {
                            return this.$store.dispatch('api_service_catalog/getCachedServiceCatalogCharacteristicByUUID', { UUID: this.char_uuid }).then(characteristic => {
                                if (this.loading == loading) {
                                    if (characteristic.ServiceCatalogEntryUUID == this.prod_uuid) {
                                        for (const key in this.characteristic) {
                                            this.characteristic[ key ] = characteristic[ key ]
                                        }
                                        this.original = { ...characteristic }

                                        this.loading = false
                                    } else {
                                        this.exit(this.service_route)
                                    }
                                }
                            }).catch(error => {
                                if (this.loading == loading) {
                                    console.error('api_service_catalog/getCachedServiceCatalogCharacteristicByUUID', error)

                                    this.exit(this.service_route)
                                }
                            })
                        }
                    }

                    return Promise.resolve(service)
                }).catch(error => {
                    if (this.loading == loading) {
                        console.error('api_service_catalog/getCachedServiceCatalogEntryByUUID', error)

                        this.exit({ name: 'services' })
                    }
                })
            }
        },

        save() {
            if (this.is_changed) {
                if (this.validation()) {
                    const processing = `${ this.prod_uuid } - ${ this.char_uuid }`

                    if (this.processing != processing) {
                        this.processing = processing

                        if (this.is_new) {
                            this.add(processing)
                        } else {
                            this.update(processing)
                        }
                    }
                } else {
                    if (this.on_sidebar) {
                        this.$parent.$refs.body.scrollTo(0, 0)
                    } else {
                        window.scrollTo(0, 0)
                    }
                }
            }
        },

        add(processing) {
            const payload = {
                ...this.characteristic,
                ServiceCatalogEntryUUID: this.prod_uuid,
            }

            this.$store.dispatch('api_service_catalog/AddServiceCatalogCharacteristic', payload).then(characteristic => {
                if (this.processing == processing) {
                    for (const key in this.characteristic) {
                        this.characteristic[ key ] = characteristic[ key ]
                    }
                    this.original = { ...characteristic }

                    if (this.on_sidebar) {
                        this.$emit('added', characteristic)
                    } else {
                        this.exit({ name: this.$route.name, params: { ...this.$route.params, char_uuid: characteristic.UUID } })
                    }

                    this.processing = false
                }
            }).catch(error => {
                this.$set(this.errors, 'save', errMessage(error))

                this.processing = false
            })
        },

        update(processing) {
            let payload = {
                UUID: this.char_uuid,
            }

            for (const key in this.characteristic) {
                if (this.characteristic[ key ] !== this.original[ key ]) {
                    payload[ key ] = this.characteristic[ key ]
                }
            }

            this.$store.dispatch('api_service_catalog/UpdateServiceCatalogCharacteristic', payload).then(characteristic => {
                if (this.processing == processing) {
                    for (const key in this.characteristic) {
                        this.characteristic[ key ] = characteristic[ key ]
                    }
                    this.original = { ...characteristic }

                    if (this.on_sidebar) {
                        this.$emit('updated', characteristic)
                    }

                    this.processing = false
                }
            }).catch(error => {
                this.$set(this.errors, 'save', errMessage(error))

                this.processing = false
            })
        },

        validation() {
            let is_valid = true
            this.errors = {}

            const values = { ...this.characteristic }

            for (const field in values) {
                if (ServiceCharacteristicsValidator.isRuleExists(field)) {
                    const error = ServiceCharacteristicsValidator.isInvalid(field, values[field])

                    if (error) {
                        this.$set(this.errors, field, error)
                        is_valid = false
                    }
                }
            }

            return is_valid
        },

        exit(route) {
            if (this.on_sidebar) {
                this.$emit('close-sidebar')
            } else if (route) {
                this.$router.push({ ...route, replace: true })
            }
        },
    },

    watch: {
        prod_uuid() {
            this.loadServiceCharacteristic()
        },
        char_uuid() {
            this.loadServiceCharacteristic()
        },
    },

    computed: {
        prod_uuid() {
            return this.uuid || this.$route.params.uuid
        },
        char_uuid() {
            return this.charUuid || this.$route.params.char_uuid
        },

        is_new() {
            return !this.char_uuid
        },

        is_changed() {
            let is_changed = false

            for (const key in this.characteristic) {
                if (this.characteristic[ key ] !== this.original[ key ]) {
                    is_changed = true
                    break
                }
            }

            return is_changed
        },

        service_route() {
            return { name: 'service', params: { uuid: this.prod_uuid } }
        },

        disabled() {
            return Boolean(this.loading) || Boolean(this.processing)
        },
    },
}
</script>

<style lang="scss">
#page-service-characteristic {
    margin: 24px 0 80px;

    .btn-back-to {
        margin-bottom: 12px;
    }

    .header-info {
        display: flex;
        justify-content: space-between;
        width: fit-content;
        min-height: 62px;
        margin-bottom: 40px;
    }

    .col-12 {
        margin-top: 30px;

        &:first-child { margin: 0 };
    }

    .board {
        padding: 24px;
        border-radius: $border-radius-secondary;
        background: var(--color-component-bg-primary);
        box-shadow: var(--box-shadow-primary);
        position: relative;

        .app-error {
            margin-top: 16px;
        }

        .app-input,
        .app-dropdown-select {
            margin: 24px 0 0;
        }

        &>.btn,
        &+.btn {
            margin-top: 32px;
        }
    }

    &.on-sidebar {
        .app-loader {
            top: -4px;
        }
    }
}

@media (max-width: $tablet-size) {
    #page-service-characteristic {
        .header-info {
            width: 100%;
            margin-bottom: 48px;
            min-height: 56px;
        }

        .board {
            padding: 16px;
        }
    }
}

@media (max-width: $mobile-size) {
    #page-service-characteristic {
        margin: 24px 0 64px;

        .btn-back-to {
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 8px;
        }

        .header-info {
            flex-direction: column;
            margin-bottom: 32px;
            min-height: 40px;
        }

        .board {
            padding: 16px 8px 8px;

            &>.btn {
                max-width: 100%;
            }

            &+.btn {
                margin-top: 15px;
            }
        }
    }
}
</style>