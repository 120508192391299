import ValidatorClass from './ValidatorClass'

export default new ValidatorClass({
    Name: {
        name: 'Service Characteristic Name',
        required: true,
        maxlength: 64,
    },
    DefaultValue: {
        name: 'Service Default Value',
        maxlength: 128,
    },
})